<template>
  <div class="iframe_box">
    dsadsadasdsdsds
    <iframe
      width="100%"
      src="/public/tutorials.html"
      frameborder="0"
      scrolling="auto"
      ref="iframeDom"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "tutorials",
};
</script>
